import {
  Button,
  Card,
  CardContent,
  ListSubheader,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import { Stack } from '@mui/system';
import { GridPaginationModel } from '@mui/x-data-grid';
import {
  addMonths,
  startOfMonth,
  endOfMonth,
  format as dateFnsFormat,
  subMonths,
  parse,
} from 'date-fns';
import {
  useCallback,
  useEffect,
  useState,
  ComponentProps,
  useRef,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { useSetRecoilState, useRecoilValue } from 'recoil';

import {
  getProducts,
  deleteProducts,
  getAttributes,
  fetchCategories,
} from '@app/adapter/catalog-service';
import { getOrderStats } from '@app/adapter/order-service';
import { getPublishedAds } from '@app/adapter/user-service';
import {
  ProductTable,
  ProductTableRow,
} from '@app/components/Catalog/ProductTable';
import { Advertising } from '@app/components/Shared/Advertising';
import { ConfirmDialog } from '@app/components/Shared/ConfirmDialog';
import { MonthSelect } from '@app/components/Shared/MonthSelect';
import { PageTitle } from '@app/components/Shared/PageTitle';
import { SearchTextBox } from '@app/components/Shared/SearchTextBox';
import { INITIAL_PAGINATE_MODEL } from '@app/components/Shared/StandardStyleDataGrid';
import {
  snackbarOpenState,
  snackbarSeverityState,
  snackbarTextState,
} from '@app/domain/app';
import { organizationSelector } from '@app/domain/organization';
import { Ad } from '@app/types/ad';
import {
  Attribute,
  Category,
  CategoryParentName,
  ProductPublicationStatus,
  SupportedProductPublicationStatus,
} from '@app/types/catalog';
import { findByGroupOrDefault } from '@app/utils/ad';
import { getMenuItems } from '@app/utils/components';
import { productStatusList, productCommutingList } from '@app/utils/constants';
import { isError } from '@app/utils/error';
import { ProductBulkEditState } from '@app/views/ProductBulkEdit';
import { ProductCreationState } from '@app/views/ProductCreation';

const ALL = 'ALL';
const adGroups = ['203'];

export function ProductManagement() {
  const navigate = useNavigate();
  const organizationState = useRecoilValue(organizationSelector);
  const setSnackbarOpen = useSetRecoilState(snackbarOpenState);
  const setSnackbarText = useSetRecoilState(snackbarTextState);
  const setSnackbarState = useSetRecoilState(snackbarSeverityState);

  const [tableRows, setTableRows] = useState<ProductTableRow[]>([]);
  const [isLoadingTable, setIsLoadingTable] = useState<boolean>(false);
  const [clickID, setClickID] = useState<string>('');
  const [selectionIds, setSelectionIds] = useState<string[]>([]);
  const [selectionCategoryName, setSelectionCategoryName] = useState('');
  const [shouldShowDeleteDialog, setShouldShowDeleteDialog] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  // 案件区分の状態
  const [categoryList, setCategoryList] = useState<Category[]>([]);
  // 診療科目リストの状態
  const [clinicalDepartmentList, setClinicalDepartmentList] = useState<
    Attribute[]
  >([]);
  // 仕事種別リストの状態
  const [jobTypeList, setJobTypeList] = useState<Attribute[]>([]);
  // 求人データ取得に使用するタイマー（無駄な検索が走らないための対策用）
  const loadProductTimerRef = useRef<{
    ms: number;
    timeoutId: NodeJS.Timeout | undefined;
  }>({ ms: 0, timeoutId: undefined });
  // 求人データの検索条件
  const [filter, setFilter] = useState<{
    clinicalDepartment: string;
    endPeriod: Date;
    jobType: string;
    keyword: string;
    startPeriod: Date;
    status: SupportedProductPublicationStatus | typeof ALL;
  }>({
    clinicalDepartment: ALL,
    endPeriod: endOfMonth(addMonths(startOfMonth(new Date()), 1)),
    jobType: ALL,
    keyword: '',
    startPeriod: startOfMonth(new Date()),
    status: ProductPublicationStatus.ACTIVE,
  });
  const [totalRowCount, setTotalRowCount] = useState(0);
  const [paginationModel, setPaginationModel] = useState<GridPaginationModel>(
    INITIAL_PAGINATE_MODEL
  );

  const isNurseProductType = window.location.href.includes(
    'paramedical-products'
  );
  const productCategoryName = isNurseProductType
    ? CategoryParentName.PARAMEDICAL
    : CategoryParentName.PRO_DOCTOR;

  const loadCategories = useCallback(async () => {
    try {
      const result = await fetchCategories();
      const category =
        result.data.value.find((c) => c.name === productCategoryName)
          ?.children ?? [];
      setCategoryList(category);
    } catch (e) {
      if (isError(e)) {
        console.error(e.message);
      }
    }
  }, [productCategoryName]);

  const loadAttributes = useCallback(async () => {
    try {
      const result = await getAttributes({
        filter: {
          groupName: ['clinicalDepartment'],
        },
        pageNumber: 0,
        pageSize: 100,
      });
      setClinicalDepartmentList(result.data.value);

      const resultJob = await getAttributes({
        filter: {
          groupName: ['jobType'],
        },
        pageNumber: 0,
        pageSize: 100,
      });
      setJobTypeList(resultJob.data.value);
    } catch (e) {
      if (isError(e)) {
        console.error(e.message);
      }
    }
  }, []);

  const loadProduct = useCallback(async () => {
    try {
      setIsLoadingTable(true);
      if (
        !filter.startPeriod ||
        !filter.endPeriod ||
        !categoryList.length ||
        !clinicalDepartmentList.length ||
        !jobTypeList.length
      ) {
        setTableRows([]);
        return;
      }
      const result = await getProducts(organizationState.id, {
        attributesClinicalDepartment:
          filter.clinicalDepartment === ALL ? [] : [filter.clinicalDepartment],
        attributesJobType: filter.jobType === ALL ? [] : [filter.jobType],
        categoryIds: categoryList.map((c) => c.id as string),
        dateRange: {
          end: filter.endPeriod.toISOString(),
          isEmpty: true,
          start: filter.startPeriod.toISOString(),
        },
        keyword: filter.keyword,
        order:
          'categoryId desc,customFields.day,customFields.startTime,customFields.endTime,createdAt desc',
        pageNumber: paginationModel.page,
        pageSize: paginationModel.pageSize,
        statuses: filter.status === ALL ? [] : [filter.status],
      });

      const productIds = result.data.value.map((p) => p.id);

      // 求人ごとの応募件数を取得
      const resultOrderStats = productIds.length
        ? await getOrderStats(organizationState.id, productIds)
        : undefined;
      if (resultOrderStats && resultOrderStats.status !== 200) {
        throw new Error(`応募件数取得 ${result.status}`);
      }

      // AIPで取得したデータをProductTableコンポーネントに渡せる形に成型
      const rows: ProductTableRow[] = result.data.value.map((product) => {
        return {
          attributesClinical: product.attributes
            .filter((a) =>
              clinicalDepartmentList.some((i) => i.id === a.attributeId)
            )
            .map((a) => ({
              id: a.attributeId,
              name: a.value,
            })),
          attributesJob: product.attributes
            .filter((a) => jobTypeList.some((i) => i.id === a.attributeId))
            .map((a) => ({
              id: a.attributeId,
              name: a.value,
            })),
          calculatedDairyPay: product.customFields.calculatedDairyPay,
          categoryName: categoryList.find((c) => c.id === product.categoryId)
            ?.name,
          commuting: productCommutingList.find(
            (c) => c.id === product.customFields.commuting
          )?.name,
          day: product.customFields.day,
          endTime: product.customFields.endTime,
          id: product.id,
          inquiry:
            resultOrderStats?.data.find((o) => o.productId === product.id)
              ?.count ?? 0,
          name: product.name,
          publicationSince: product.publication.since,
          publicationUntil: product.publication.until,
          searchConditionWeek: product.customFields.repeatWeek,
          startTime: product.customFields.startTime,
          status: product.publication.status,
        } as ProductTableRow;
      });
      setTableRows(rows);
      setTotalRowCount(result.data.total);
    } catch (e) {
      if (isError(e)) {
        console.error(e.message);
      }
      setSnackbarText('求人一覧の取得に失敗しました。');
      setSnackbarOpen(true);
    } finally {
      setIsLoadingTable(false);
    }
  }, [
    filter,
    categoryList,
    clinicalDepartmentList,
    jobTypeList,
    organizationState.id,
    paginationModel,
    setSnackbarText,
    setSnackbarOpen,
  ]);

  const handleStartPeriodChange: NonNullable<
    ComponentProps<typeof MonthSelect>['onChange']
  > = useCallback((value) => {
    loadProductTimerRef.current = { ...loadProductTimerRef.current, ms: 500 }; // NOTE:無駄な検索防止に一定時間検索待機
    setFilter((prevState) => ({
      ...prevState,
      startPeriod: startOfMonth(parse(value, 'yyyy/M', new Date())),
    }));
  }, []);

  const handleEndPeriodChange: NonNullable<
    ComponentProps<typeof MonthSelect>['onChange']
  > = useCallback((value) => {
    loadProductTimerRef.current = { ...loadProductTimerRef.current, ms: 500 }; // NOTE:無駄な検索防止に一定時間検索待機
    setFilter((prevState) => ({
      ...prevState,
      endPeriod: endOfMonth(parse(value, 'yyyy/M', new Date())),
    }));
  }, []);

  const handleCreateButtonClick: NonNullable<
    ComponentProps<typeof Button>['onClick']
  > = useCallback(() => {
    navigate('register');
  }, [navigate]);

  const handleBulkCancelButtonClick: NonNullable<
    ComponentProps<typeof Button>['onClick']
  > = useCallback(() => {
    setSelectionIds([]);
  }, [setSelectionIds]);

  const handleBulkEditButtonClick: NonNullable<
    ComponentProps<typeof Button>['onClick']
  > = useCallback(() => {
    navigate('bulk-edit', {
      state: { ids: selectionIds } as ProductBulkEditState,
    });
  }, [navigate, selectionIds]);

  const handleBulkDeleteButtonClick: NonNullable<
    ComponentProps<typeof Button>['onClick']
  > = useCallback(() => {
    setShouldShowDeleteDialog((current) => (current ? current : true));
  }, [setShouldShowDeleteDialog]);

  const handleChangeClinicalDepartment: NonNullable<
    ComponentProps<typeof Select<string>>['onChange']
  > = useCallback((e) => {
    loadProductTimerRef.current = { ...loadProductTimerRef.current, ms: 500 }; // NOTE:無駄な検索防止に一定時間検索待機
    setFilter((prevState) => ({
      ...prevState,
      clinicalDepartment: e.target.value,
    }));
  }, []);

  const handleChangeJobType: NonNullable<
    ComponentProps<typeof Select<string>>['onChange']
  > = useCallback((e) => {
    loadProductTimerRef.current = { ...loadProductTimerRef.current, ms: 500 }; // NOTE:無駄な検索防止に一定時間検索待機
    setFilter((prevState) => ({
      ...prevState,
      jobType: e.target.value,
    }));
  }, []);

  const handleStatusChange: NonNullable<
    ComponentProps<typeof Select<string>>['onChange']
  > = useCallback((e) => {
    loadProductTimerRef.current = { ...loadProductTimerRef.current, ms: 500 }; // NOTE:無駄な検索防止に一定時間検索待機
    setFilter((prevState) => ({
      ...prevState,
      status: e.target.value as SupportedProductPublicationStatus | typeof ALL,
    }));
  }, []);

  const handleRowSelectionChange: ComponentProps<
    typeof ProductTable
  >['onRowSelectionChange'] = useCallback(
    (ids: string[]) => {
      setSelectionIds(ids);
      setSelectionCategoryName(
        ids.length
          ? tableRows.find((r) => ids.some((id) => id === r.id))
              ?.categoryName ?? ''
          : ''
      );
    },
    [setSelectionIds, setSelectionCategoryName, tableRows]
  );

  const handleRowClick: ComponentProps<typeof ProductTable>['onRowClick'] =
    useCallback(
      async (row) => {
        navigate(`${row.id}`);
      },
      [navigate]
    );

  const handleRowClickCopy: ComponentProps<
    typeof ProductTable
  >['onRowClickCopy'] = useCallback(
    (row: ProductTableRow) => {
      navigate('register', {
        state: { id: row.id, mode: 'copy' } as ProductCreationState,
      });
    },
    [navigate]
  );

  const handleRowClickDelete: ComponentProps<
    typeof ProductTable
  >['onRowClickDelete'] = useCallback(
    (row: ProductTableRow) => {
      setClickID(row.id);
      setShouldShowDeleteDialog((current) => (current ? current : true));
    },
    [setClickID, setShouldShowDeleteDialog]
  );

  const handleCloseDeleteDialog: ComponentProps<
    typeof ConfirmDialog
  >['onClose'] = useCallback(
    async (confirm) => {
      const ids = clickID ? [clickID] : selectionIds;
      if (!confirm) {
        setClickID('');
        setShouldShowDeleteDialog(false);
        return;
      }
      setIsDeleting(true);
      try {
        await deleteProducts(organizationState.id, ids);
        setSnackbarText(`${ids.join('、')}の求人を削除しました。`);
        setSnackbarState('info');
      } catch (e) {
        if (isError(e)) {
          console.error(e.message);
        }
        setSnackbarText('求人の削除に失敗しました。');
      } finally {
        setClickID('');
        setSelectionIds([]);
        setIsDeleting(false);
        setShouldShowDeleteDialog(false);
        setSnackbarOpen(true);
        await loadProduct();
      }
    },
    [
      loadProduct,
      clickID,
      selectionIds,
      setClickID,
      setSelectionIds,
      setSnackbarOpen,
      setSnackbarState,
      setSnackbarText,
      organizationState.id,
    ]
  );

  const [ads, setAds] = useState<Ad[]>([]);
  const fetchAds = useCallback(async () => {
    try {
      const result = await getPublishedAds({
        filter: { publicationGroup: adGroups },
        top: 1,
      });
      setAds(result.data.value || []);
    } catch (e) {
      if (isError(e)) {
        throw new Error(e.message);
      }
    }
  }, []);

  useEffect(() => {
    void fetchAds();
    void (async () => {
      await loadCategories();
      await loadAttributes();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // NOTE:無駄な検索防止に一定時間検索待機
    clearTimeout(loadProductTimerRef.current.timeoutId);
    const timeoutId = setTimeout(() => {
      void loadProduct();
    }, loadProductTimerRef.current.ms);
    loadProductTimerRef.current = { ms: 0, timeoutId };
  }, [loadProduct]);

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{ mb: 2 }}
      >
        <Stack direction="row" spacing={3} alignItems="center">
          <PageTitle title="掲載管理" data-e2e="product-title" />
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography>期間</Typography>
            <MonthSelect
              value={dateFnsFormat(filter.startPeriod, 'yyyy/M')}
              startMonth={dateFnsFormat(subMonths(new Date(), 36), 'yyyy/M')}
              monthCount={72}
              onChange={handleStartPeriodChange}
            />
            <Typography>〜</Typography>
            <MonthSelect
              value={dateFnsFormat(filter.endPeriod, 'yyyy/M')}
              startMonth={dateFnsFormat(subMonths(new Date(), 36), 'yyyy/M')}
              monthCount={72}
              onChange={handleEndPeriodChange}
            />
          </Stack>
        </Stack>
        <Button
          color="primary"
          variant="contained"
          size="small"
          onClick={handleCreateButtonClick}
          sx={{ width: '120px' }}
        >
          新規登録
        </Button>
      </Stack>
      <Card>
        <CardContent>
          {selectionIds.length > 0 && (
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="space-between"
              sx={{ mb: 2 }}
            >
              <Button
                variant="outlined"
                color="secondary"
                size="small"
                onClick={handleBulkCancelButtonClick}
                sx={{ width: '120px' }}
              >
                キャンセル
              </Button>
              <Stack direction="row" spacing={1} alignItems="center">
                <Button
                  variant="outlined"
                  color="secondary"
                  size="small"
                  onClick={handleBulkEditButtonClick}
                  sx={{ width: '120px' }}
                >
                  一括編集
                </Button>
                <Button
                  variant="outlined"
                  color="error"
                  size="small"
                  onClick={handleBulkDeleteButtonClick}
                  sx={{
                    width: '120px',
                  }}
                >
                  一括削除
                </Button>
              </Stack>
            </Stack>
          )}
          <Stack direction="row" spacing={2} alignItems="center">
            <SearchTextBox
              value={filter.keyword}
              onSubmit={(value) => {
                setFilter((prevState) => ({
                  ...prevState,
                  keyword: value,
                }));
              }}
            />
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography variant="body2">募集科目</Typography>
              <Select
                size="small"
                value={filter.clinicalDepartment}
                onChange={handleChangeClinicalDepartment}
                sx={{ width: '206px' }}
              >
                <MenuItem value={ALL}>すべて</MenuItem>
                {clinicalDepartmentList
                  .reduce((list, a) => {
                    const itemValues = a.items?.[0];
                    if (!itemValues) {
                      return list;
                    }
                    const item = { id: a.id, name: itemValues.value };
                    const group = list.find(
                      (g) => g.groupName === itemValues.groupName
                    );
                    if (group) {
                      group.items.push(item);
                    } else {
                      list.push({
                        groupName: itemValues.groupName,
                        items: [item],
                      });
                    }
                    return list;
                  }, [] as { groupName: string; items: { id: string; name: string }[] }[])
                  .map((g) => [
                    <ListSubheader key={g.groupName}>
                      {g.groupName}
                    </ListSubheader>,
                    getMenuItems(g.items, g.groupName),
                  ])}
              </Select>
            </Stack>
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography variant="body2">仕事種別</Typography>
              <Select
                size="small"
                value={filter.jobType}
                onChange={handleChangeJobType}
                sx={{ width: '174px' }}
              >
                <MenuItem value={ALL}>すべて</MenuItem>
                {getMenuItems(jobTypeList)}
              </Select>
            </Stack>
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography variant="body2">ステータス</Typography>
              <Select
                size="small"
                value={filter.status}
                onChange={handleStatusChange}
                sx={{ width: '94px' }}
              >
                <MenuItem value={ALL}>すべて</MenuItem>
                {getMenuItems(productStatusList)}
              </Select>
            </Stack>
          </Stack>
        </CardContent>
        <ProductTable
          rows={tableRows}
          rowCount={totalRowCount}
          rowSelectionIds={selectionIds}
          loading={isLoadingTable}
          paginationModel={paginationModel}
          selectionCategoryName={selectionCategoryName}
          onRowClick={handleRowClick}
          onRowClickCopy={handleRowClickCopy}
          onRowClickDelete={handleRowClickDelete}
          onRowSelectionChange={handleRowSelectionChange}
          onPaginationModelChange={setPaginationModel}
        />
      </Card>
      <Stack justifyContent="end" flexDirection="row" mt={2}>
        <Advertising item={findByGroupOrDefault(ads, adGroups[0])} />
      </Stack>
      <ConfirmDialog
        title={`${
          clickID ? clickID : selectionIds.join('、')
        }の求人を本当に削除しますか？`}
        okButtonText="削除"
        okButtonLoading={isDeleting}
        open={shouldShowDeleteDialog}
        onClose={handleCloseDeleteDialog}
      />
    </>
  );
}
